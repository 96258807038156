import PropTypes from 'prop-types';
import { connect, Provider, useSelector } from 'react-redux';
import React from 'react';
import Parser from 'html-react-parser';

function CommonRenderProp(props) {
	return props.render(props.commonData);
}

CommonRenderProp.propTypes = {
	render: PropTypes.func.isRequired,
	commonData: PropTypes.shape({
		labels: PropTypes.shape({}).isRequired,
		sitemap: PropTypes.shape({}).isRequired,
		prefs: PropTypes.shape({}).isRequired,
		flags: PropTypes.shape({}).isRequired
	}).isRequired
};

CommonRenderProp.displayName = 'CommonRenderProp';

const labelAsHTML = (label, Tag = React.Fragment) => {
	const html = Parser(label);
	return <Tag>{html}</Tag>;
};

const labelMethods = (labels) => {
	return {
		has: (key) => labels[key] !== undefined,
		get: (key) => labels[key] || key,
		getKeys: () => Object.keys(labels),
		getAll: () => labels,
		getHTML: (key, Tag) => labelAsHTML(labels[key] || key, Tag)
	};
};

const sitemapMethods = (sitemap) => {
	return {
		has: (alias) => sitemap[alias] !== undefined,
		get: (alias) => sitemap[alias] || null,
		getAll: () => sitemap
	};
};

export const useFlags = () => useSelector((state) => state.flags);

export const useLabels = () => {
	const labels = useSelector((state) => state.labels);
	return labelMethods(labels);
};

export const usePrefs = () => useSelector((state) => state.prefs);

export const useRequestData = () => useSelector((state) => state.requestData);

export const useSitemap = () => {
	const sitemap = useSelector((state) => state.sitemap);
	return sitemapMethods(sitemap);
};

const mapStateToProps = (state) => {
	return {
		commonData: {
			labels: labelMethods(state.labels),
			sitemap: sitemapMethods(state.sitemap),
			prefs: state.prefs,
			flags: state.flags
		}
	};
};

export const Common = connect(
	mapStateToProps,
	null, // mapDispatchToProps
	null, // mergeProps
	{ pure: false }
)(CommonRenderProp);

Common.displayName = 'Common';

export function createStore(state) {
	return {
		subscribe: () => {},
		dispatch: () => {},
		getState: () => state
	};
}

export function CommonTester(props) {
	const { initialState, flags, labels, sitemap, prefs, render } = props;
	const store = createStore({
		...initialState,
		flags,
		labels,
		sitemap,
		prefs
	});

	return <Provider store={store}>{render()}</Provider>;
}

CommonTester.displayName = 'CommonTester';

CommonTester.propTypes = {
	render: PropTypes.func.isRequired,
	labels: PropTypes.shape({}),
	sitemap: PropTypes.shape({}),
	prefs: PropTypes.shape({}),
	flags: PropTypes.shape({}),
	initialState: PropTypes.shape({})
};

CommonTester.defaultProps = {
	labels: {},
	sitemap: {},
	prefs: {},
	flags: {},
	initialState: {}
};
